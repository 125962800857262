import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import BaseLayout from "../components/layout"
import Seo from "../components/seo";
import HorizontalRule from "../components/horizontal-rule";
import BookSummary from "../components/book-summary";
import ChapterSummary from "../components/chapter-summary";
import NewsletterSignup from "../components/newsletter-signup";
import TextBlock from "../components/text-block";
import ArticleImage from "../components/article-image";


const HomePage = (props) => {
  let data = useStaticQuery(pageQuery);
  const featuredArticles = [
      data.weatheredPearls.nodes[0],
      data.londinium.nodes[0],
      data.throughBones.nodes[0],
      data.youngManSea.nodes[0]
  ];

  const site = data.site;
  const featuredBook = data.featuredBook.nodes[0];

  const headerStyles = {
    backgroundImage: 'url("https://media.zackthoutt.com/logo/Other/mountain-cover-web.jpg")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  }
    let breadcrumbList = [
      [
        {
          title: 'Home',
        }
      ]
    ]

  return (
    <BaseLayout>
      <Seo title="Home" pathname={props.location.pathname} breadcrumbList={breadcrumbList}></Seo>
      <div className="w-full bg-green [height:600px] md:[height:700px]" style={headerStyles}>
        <div className="layout-base-w py-20 md:py-40 app-px-base text-offwhite h-full">
          <div className="flex flex-col justify-between h-full">
            <div>
              <HorizontalRule ruleType="1"></HorizontalRule>
              <h1 style={{'maxWidth': '620px'}} className="text-header-1 w-full">A Digital Library of Stories</h1>
            </div>
            <div>
              <h2 className="text-header-1 mb-8 md:mb-16">By Zack Thoutt</h2>
              <div className="flex justify-start items-center">
                <Link to="/writing/library/"><button className="btn btn-skinny md:btn-medium md:btn-wide btn-white mr-8">Library →</button></Link>
                <Link to="/writing/latest/"><button className="btn btn-skinny md:btn-medium md:btn-wide btn-white">Latest →</button></Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="layout-base-w app-px-base">
        <div className="my-40 md:my-80">
          <h3 className="text-subheader-1 text-mint border-b-base pb-4 mb-8">Featured Book</h3>
          <BookSummary book={featuredBook} summaryLayout="featured"></BookSummary>
        </div>

        <div className="mt-80 mb-32">
          <h3 className="text-subheader-1 text-mint border-b-base pb-4 mb-24">Featured Chapters</h3>
          <div className="flex justify-between gap-24 md:gap-8 flex-col md:flex-row">
            {featuredArticles.slice(0, 2).map((chapter, index) => (
              <ChapterSummary key={index} chapter={chapter}></ChapterSummary>
            ))}
          </div>

          <div className="my-40">
            <NewsletterSignup></NewsletterSignup>
          </div>

          <div className="flex justify-between gap-24 md:gap-8 flex-col md:flex-row">
            {featuredArticles.slice(2, 4).map((chapter, index) => (
              <ChapterSummary key={index} chapter={chapter}></ChapterSummary>
            ))}
          </div>
        </div>

        <div className="mt-40 mb-32 text-mint">
          <HorizontalRule ruleType="6"></HorizontalRule>
        </div>

        <div className="mt-32 mb-40 mx-auto" style={{'maxWidth': '620px'}}>
          <h2 className="text-center text-header-2 text-mint mb-8">About the Author</h2>
          <div className="mx-auto my-12 block app-image max-w-full sm:[max-width:300px]">
            <ArticleImage src="https://media.zackthoutt.com/img/zack-profile-small.jpeg" alt="Zack profile picture"/>
          </div>
          <TextBlock text={site.siteMetadata.aboutText}></TextBlock>
          <Link to="/about/"><button className="btn btn-medium btn-gold mt-12">Read More</button></Link>
        </div>
      </div>
    </BaseLayout>
  )
}

export default HomePage

export const pageQuery = graphql`
  query {
    weatheredPearls: allMdx(
      sort: { fields: [frontmatter___published_date], order: DESC }
      filter: { slug: { eq: "writing/tales-of-twilight/1-weathered-pearls" } } 
    ) {
      nodes {
        id
        ...ArticleDetails    
      }
    }
    throughBones: allMdx(
      sort: { fields: [frontmatter___published_date], order: DESC }
      filter: { slug: { eq: "writing/tales-of-twilight/2-through-bone-like-butter" } } 
    ) {
      nodes {
        id
        ...ArticleDetails    
      }
    }
    youngManSea: allMdx(
      sort: { fields: [frontmatter___published_date], order: DESC }
      filter: { slug: { eq: "writing/nomad-life/5-the-young-man-and-the-sea" } } 
    ) {
      nodes {
        id
        ...ArticleDetails    
      }
    }
    londinium: allMdx(
      sort: { fields: [frontmatter___published_date], order: DESC }
      filter: { slug: { eq: "writing/nomad-life/16-londinium" } } 
    ) {
      nodes {
        id
        ...ArticleDetails    
      }
    }
    site {
      siteMetadata {
        ...CommonMetaData
        company
      }
    }
    allCollections(
      sort: { order: ASC, fields: [sort_order] }
    ) {
      nodes {
        id
        sort_order
        title
        genres
        summary
      }
    }
    tagsMdx: allMdx(
      filter: { frontmatter: { published: { eq: true }, record_type: { eq: "story" } } }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    
    featuredBook: allCollections(
      filter: { title: { eq: "Wally and the Holiday Jailbreak" } }
      limit: 1
    ) {
      nodes {
        ...CollectionFields
      }
    }
  }
`