import React from "react";
import ArticleMetadata from "./article-metadata";
import TextBlock from "./text-block";
import { Link } from "gatsby"
import kebabCase from "lodash/kebabCase"

export default function BookSummary(props) {
  let summaryLayout = 'featured';
  if (props.summaryLayout) {
    summaryLayout = props.summaryLayout;
  }

  let showCover = true;
  if (props.showCover !== undefined) {
    showCover = props.showCover;
  }

  return (
    <>
      {summaryLayout === 'featured' &&
        <div>
          <Link to={`/writing/library/${kebabCase(props.book.title)}`}>
            <h3 className={`text-mint text-header-1`}>{props.book.title}{props.book.subtitle && <> {props.book.subtitle}</>}</h3>
          </Link>

          <div className="mt-8">
            <ArticleMetadata genreList={props.book.genres} publishedDate={props.book.published_date}></ArticleMetadata>
          </div>

          <div className={`${showCover && props.book.book_cover.portrait ? 'base-w' : 'reading-w'} mt-16 mx-auto flex gap-10 flex-col md:flex-row`}>
            {showCover && props.book.book_cover.portrait &&
              <div className="mx-auto mb-4 md:mb-0 w-full sm:[width:275px] sm:[flex-basis:275px]">
                <img className="w-full md:[margin-top:11px]" src={props.book.book_cover.portrait} alt={`${props.book.title} Book Cover`}/>
              </div>
            }
            <div className="shrink grow flex-1">
              <TextBlock text={props.book.summary} textStyle="italic" textSize="large"></TextBlock>
              <Link to={`/writing/library/${kebabCase(props.book.title)}`}>
                <button className="btn btn-medium btn-gold mt-12">Read More →</button>
              </Link>
            </div>
          </div>
        </div>
      }
      {summaryLayout === 'list' &&
        <div className={`${showCover && props.book.book_cover.portrait ? 'base-w' : 'reading-w'} mx-auto flex gap-10 flex-col md:flex-row`}>
          {showCover && props.book.book_cover.portrait &&
            <div style={{'width': '275px', 'flexBasis': '275px'}} className="hidden md:block">
              <img className="w-full" src={props.book.book_cover.portrait} alt={`${props.book.title} Book Cover`}/>
            </div>
          }
          <div className="shrink grow flex-1">
            <Link to={`/writing/library/${kebabCase(props.book.title)}`}>
              <h3 className={`text-mint text-header-2`}>{props.book.title}{props.book.subtitle && <> {props.book.subtitle}</>}</h3>
            </Link>

            <div className="mt-4">
              <ArticleMetadata genreList={props.book.genres}></ArticleMetadata>
            </div>

            {showCover && props.book.book_cover.portrait &&
              <div className="md:hidden mt-10 mb-12 mx-auto w-full sm:[max-width:275px]">
                <img className="w-full" src={props.book.book_cover.portrait} alt={`${props.book.title} Book Cover`}/>
              </div>
            }

            <div className="mt-8">
              <TextBlock text={props.book.summary} textStyle="normal" textSize="small"></TextBlock>
              <Link to={`/writing/library/${kebabCase(props.book.title)}`}>
                <button className="btn btn-medium btn-mint mt-12">Read More</button>
              </Link>
            </div>
          </div>
        </div>
      }
    </>
  )
}